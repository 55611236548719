import _ from "lodash";

export default function () {
  return {
    field: null,
    title: null,
    multiple: false,
    shown: false,
    isApplied: false,
    displayValue: null,
    fields: [],
    values: null,
    init() {
      const data = this.$root.dataset;
      this.field = data.field;
      this.title = data.title;
      this.multiple = data.multiple === "true";
      this.$watch("filters", (filters) => this.isApplied);
      this.$watch("values", (value) => (this.displayValue = this.previewText()));
      this.$nextTick(() => this.updateFromUpstream());
    },
    previewText() {
      const obj = JSON.parse(JSON.stringify(this.values));
      let result = "";
      Object.entries(obj).forEach((e) => (result += `(${e[0]} -> ${e[1]})`));
      return result;
    },
    extractKeyVal() {
      let regex = new RegExp(`${this.field}\\[(.*)\\]`);
      let obj = {};
      Object.entries(this.filters).forEach((e) => {
        if (regex.test(e[0])) {
          obj[regex.exec(e[0])[1]] = e[1];
        }
      });
      return obj;
    },
    updateFromUpstream() {
      // Extract metadata if any
      const metadata = this.extractKeyVal();

      if(Object.keys(metadata).length === 0 && this.fields.length < 1){
        this.addNewInputEntry()
        return;
      }

      if(this.fields.length === 1){
        return;
      }

      this.values = {}
      this.fields =[]

      Object.entries(metadata).forEach((m) => {
        this.values[m[0]] = m[1];
        this.addNewInputEntry(m[0], m[1]);
      });

      this.isApplied = Object.keys(this.values).length > 0;
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.$nextTick(() => this.updateFromUpstream());
    },
    apply() {
      // Get user entered data
      const fieldsArray = this.fields;

      // Discard empty keys, returns an array of metadata fields
      const valuesToApply = fieldsArray.filter((e) => e.key !== "");

      if (valuesToApply.length > 0) {

        const metadata = {};

        valuesToApply.forEach(({ key, value }) => {
          metadata[key]= value
        });

        Object.entries(metadata).forEach( m => {
          this.filters[`metadata[${m[0]}]`] = m[1]
        })

        this.values = metadata;
        this.isApplied = true;
      } else {
        delete this.filters[this.field];
      }
      this.hide();
    },
    remove() {
      const metadata_keys = Object.keys(this.extractKeyVal());
      metadata_keys.forEach(e => {
        delete this.filters[`${this.field}[${e}]`];
      })
      this.isApplied = false;
      this.hide();
    },
    randomKey() {
      return Array(32)
        .fill(0)
        .map((_) => ((Math.random() * 16) | 0).toString(16))
        .join("");
    },
    addNewInputEntry(key = "", value = "") {
      this.fields.push({ id: this.randomKey(), key: key, value: value });
    },
    async onInput(event) {
      if (!this.multiple || event.inputType !== "insertFromPaste") {
        return;
      }

      let clipboardText = null;

      try {
        clipboardText = await navigator.clipboard.readText();
      } catch {
        // Ignore when permission is not given
        return;
      }

      if (!clipboardText.match(/\n/)) {
        return;
      }

      const fragments = clipboardText
        .split(/\n+/)
        .map((f) => f.trim())
        .filter((f) => f.length > 0);

      for (const f of fragments) {
        this.addNewInputEntry(f);
      }

      const additionalText = clipboardText
        .replace(/^.+\n/, "")
        .replace(/\n/g, " ");

      event.target.values = event.target.values
        .replace(additionalText, "")
        .trim();
    },
  };
}
