import _ from "lodash";
import { DateTime } from "luxon";

export default function () {
  return {
    field: null,
    title: null,
    shown: false,
    mode: "equal", // equal, between, gt, lt
    firstValue: null,
    secondValue: null,
    isApplied: false,
    init() {
      this.field = this.$root.dataset.field;
      this.title = this.$root.dataset.title;
      this.$watch(
        "filters",
        (filters) => ( this.isApplied = ( this.field in filters || this.field+'[gt]' in filters || this.field+'[lt]' in filters  ))
      );

      this.$nextTick(() => {this.updateFromUpstream();})
    },
    get displayValue() {
      const current_local = document.getElementsByTagName("html")[0].getAttribute('lang') || 'en';

      if(this.mode === "equal"){
        return new Date(this.firstValue).toDateString();
      }
      if(this.mode === "lt"){
        return `${current_local === 'en' ? 'before' : 'قبل'} ${new Date(this.secondValue).toDateString()}`;
      }
      if(this.mode === "gt"){
        return `${current_local === 'en' ? 'after' : 'بعد'} ${new Date(this.firstValue).toDateString()}`;
      }
      if(this.mode === "between"){
        return `${new Date(this.firstValue).toDateString()} -> ${new Date(this.secondValue).toDateString()}`;
      }

    },
    updateFromUpstream() {
        const f = this.firstValue = this.upstreamFirstValue();
        const s = this.secondValue = this.upstreamSecondValue();

        if (!(f && s)) {
          return;
        }
        if (f === s) {
          this.mode = "equal";
        } else if (!s) {
          this.mode = "gt";
        } else if (!f) {
          this.mode = "lt";
        } else {
          this.mode = "between";
        }
    },
    upstreamFirstValue() {
      return this.utcTimeToLocal(
        _(this.filters).get(`${this.field}[gt]`) || null
      );
    },
    upstreamSecondValue() {
      return this.utcTimeToLocal(
        _(this.filters).get(`${this.field}[lt]`) || null
      );
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.updateFromUpstream();
    },
    localTimeToUTC(local) {
      const date = DateTime.fromISO(local);
      if (date.invalid !== null) {
        return null;
      }

      return date
        .toUTC()
        .toISO()
        .replace(/.\d{3}Z/, "Z");
    },
    utcTimeToLocal(utc) {
      const date = DateTime.fromISO(utc);
      if (date.invalid !== null) {
        return null;
      }

      return date.toLocal().toFormat("yyyy-MM-dd'T'HH:mm");
    },
    firstToApply() {
      let date = this.localTimeToUTC(this.firstValue);

      if (this.mode === "lt") {
        date = null;
      }

      return date;
    },
    secondToApply() {
      let date = this.localTimeToUTC(this.secondValue);

      if (this.mode === "equal") {
        date = this.firstToApply();
      }

      if (this.mode === "gt") {
        date = null;
      }

      return date;
    },
    apply() {
      let filters = {
        gt: this.firstToApply(),
        lt: this.secondToApply(),
      };

      filters = _(filters).omitBy(_.isEmpty).value();

      if (!_.isEmpty(filters)) {
        this.filters[`${this.field}[gt]`] = filters.gt;
        this.filters[`${this.field}[lt]`] = filters.lt;
      } else {
        delete this.filters[this.field];
      }

      this.hide();
    },
    remove() {
      this.firstValue = null;
      this.secondValue = null;

      delete this.filters[`${this.field}`];
      delete this.filters[`${this.field}`];
      this.hide();
    },
  };
}
