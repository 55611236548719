import flatpickr from "flatpickr";
export default () => {
  const el = document.getElementById("create-invoice-picker");

  if (!el) {
    return;
  }
  const date = new Date()
  const minDate = new Date(date.getTime() + 15 * 60 * 1000);

  flatpickr(el,{
    minDate: minDate,
    enableTime: true,
    time_24hr: true
  });

};
