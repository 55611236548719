export default function metadataAdder(metadata = null) {
  return {
    init() {
      if (metadata === null) {
        this.fields.push({ id: Math.random() * 10000000, key: "", val: ""});
      } else {
        Object.entries(metadata).forEach((m) => {
          this.fields.push({
            id: Math.random() * 10000000,
            key: m[0],
            val: m[1],
          });
        });
      }
    },
    fields: [],
    prepair_for_api() {
      let metadata = {};
      this.fields.forEach((e) => {
        metadata[e.key] = e.val;
      });
    },
    addNewField() {
      this.fields.push({ id: Math.random() * 10000000, key: "", val: ""});
    },
    removeField(id) {
      this.fields = this.fields.filter((f) => f.id !== id);
    }
  };
}
