export default function () {
  return {
    shown: false,
    value: "",
    isApplied: false,
    displayValue: null,
    field: null,
    title: null,
    options: {},
    init() {
      const data = this.$root.dataset;

      this.field = data.field;
      this.title = data.title;
      this.options = JSON.parse(data.options);
      this.$watch(
        "filters",
        (filters) => (this.isApplied = this.field in filters)
      );
      this.$watch(
        "isApplied",
        (isApplied) =>
          (this.displayValue = isApplied ? this.selectedText() : null)
      );
      this.$nextTick(() => this.updateFromMaster());
    },
    updateFromMaster() {
      this.value = this.filters[this.field] || "";
    },
    selectedText() {
      return this.$refs.select.selectedOptions[0]?.innerText;
    },
    show() {
      this.shown = true;
    },
    hide() {
      this.shown = false;
      this.$nextTick(() => this.updateFromMaster());
    },
    apply() {
      this.filters[this.field] = this.value;
      this.hide();
    },
    remove() {
      this.value = "";
      delete this.filters[this.field];
      this.hide();
    },
  };
}
