import 'flowbite';
import 'flatpickr';
import '../charts/setup';
import '../datepickers/setup'
import '~/index.css'

import { DateTime } from 'luxon';
import MiniSearch from 'minisearch';
import _ from 'lodash';

window.luxon = DateTime;
window.MiniSearch = MiniSearch;
window._ = _;

// This should be last
import '../alpine-setup';
