export default (currencies = "[SAR]") => ({
  init() {
    this.currency_symbol("SAR");
  },
  all_currencies: currencies,
  current: null,
  // find the currency symbol, the same as the current one
  currency_symbol(currency = "SAR") {
    for (let i = 0; i < this.all_currencies.length; i++) {
      if (this.all_currencies[i]["iso_code"] === currency) {
        this.current = this.all_currencies[i]["symbol"];
      }
    }
  },
});
